"use client";

import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Link from "next/link";
import { Button } from "@/components/ui/button";
import { ChevronDown, Mail, Settings, Users, Menu, X } from "lucide-react";

export function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <motion.nav
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
          scrolled
            ? "bg-slate-900/95 backdrop-blur-md shadow-lg"
            : "bg-transparent"
        }`}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-20">
            {/* Logo */}
            <div className="flex items-center">
              <Link href="/" className="relative group">
                <span className="text-2xl font-bold bg-gradient-to-r from-primary to-purple-500 bg-clip-text text-transparent relative z-10 py-2 px-3">
                  Thuegpu.vn
                </span>
                <div className="absolute inset-0 bg-white/0 group-hover:bg-white/10 rounded-lg transition-colors duration-200" />
              </Link>
            </div>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center justify-center flex-1 space-x-1">
              <div className="flex items-center justify-center space-x-1">
                <Link href="/features">
                  <Button
                    variant="ghost"
                    className="text-white hover:text-white hover:bg-primary/20 transition-all duration-300 rounded-lg group relative overflow-hidden"
                  >
                    <span className="relative z-10">Tính năng</span>
                  </Button>
                </Link>
                <Link href="/pricing">
                  <Button
                    variant="ghost"
                    className="text-white hover:text-white hover:bg-primary/20 transition-all duration-300 rounded-lg group relative overflow-hidden"
                  >
                    <span className="relative z-10">Bảng giá</span>
                  </Button>
                </Link>
                <Link href="/docs">
                  <Button
                    variant="ghost"
                    className="text-white hover:text-white hover:bg-primary/20 transition-all duration-300 rounded-lg group relative overflow-hidden"
                  >
                    <span className="relative z-10">Tài liệu</span>
                  </Button>
                </Link>
                <Link href="/blog">
                  <Button
                    variant="ghost"
                    className="text-white hover:text-white hover:bg-primary/20 transition-all duration-300 rounded-lg group relative overflow-hidden"
                  >
                    <span className="relative z-10">Tin tức</span>
                  </Button>
                </Link>
              </div>
            </div>

            {/* Auth Buttons */}
            <div className="hidden md:flex items-center space-x-3">
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Button
                  variant="ghost"
                  className="text-white hover:text-white hover:bg-primary/20 transition-all duration-300 rounded-lg"
                >
                  Đăng nhập
                </Button>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Button className="bg-primary hover:bg-primary text-white shadow-lg shadow-primary/25 rounded-lg">
                  <span className="relative z-10">Đăng ký</span>
                </Button>
              </motion.div>
            </div>

            {/* Mobile Menu Button */}
            <motion.button
              whileTap={{ scale: 0.95 }}
              className="md:hidden text-white p-2 hover:bg-white/10 rounded-lg"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              {isMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </motion.button>
          </div>
        </div>
      </motion.nav>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
            className="md:hidden fixed inset-0 z-40 bg-slate-900 pt-20"
          >
            <div className="flex flex-col px-4 py-6 space-y-4 bg-slate-900 h-full">
              <Link href="/features" onClick={() => setIsMenuOpen(false)}>
                <Button
                  variant="ghost"
                  className="text-white w-full justify-start hover:bg-white/10"
                >
                  Tính năng
                </Button>
              </Link>
              <Link href="/pricing" onClick={() => setIsMenuOpen(false)}>
                <Button
                  variant="ghost"
                  className="text-white w-full justify-start hover:bg-white/10"
                >
                  Bảng giá
                </Button>
              </Link>
              <Link href="/docs" onClick={() => setIsMenuOpen(false)}>
                <Button
                  variant="ghost"
                  className="text-white w-full justify-start hover:bg-white/10"
                >
                  Tài liệu
                </Button>
              </Link>
              <Link href="/blog" onClick={() => setIsMenuOpen(false)}>
                <Button
                  variant="ghost"
                  className="text-white w-full justify-start hover:bg-white/10"
                >
                  Tin tức
                </Button>
              </Link>
              <div className="pt-4 space-y-2">
                <Button
                  variant="ghost"
                  className="text-white w-full justify-center hover:bg-white/10"
                >
                  Đăng nhập
                </Button>
                <Button className="w-full bg-primary hover:bg-primary/90 shadow-lg shadow-primary/25">
                  Đăng ký
                </Button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
