'use client'

import React from 'react'
import type { JSX } from 'react'
import { motion } from 'framer-motion'
import Link from 'next/link'
import { Facebook, Twitter, Github, Mail, MapPin, Phone } from 'lucide-react'

export function Footer(): JSX.Element {
  const footerAnimation = {
    initial: { y: 50, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    transition: { duration: 0.5 }
  }

  return (
    <motion.footer 
      {...footerAnimation}
      className="relative z-10 bg-gradient-to-b from-slate-900 to-slate-950 text-gray-300"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        {/* Main Footer Content */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {/* Company Info */}
          <div className="space-y-6">
            <Link href="/">
              <h3 className="text-2xl font-bold bg-gradient-to-r from-primary to-purple-500 bg-clip-text text-transparent">
                Thuegpu.vn
              </h3>
            </Link>
            <p className="text-gray-400 max-w-xs">
              Dịch vụ cho thuê máy chủ GPU hàng đầu Việt Nam với công nghệ tiên tiến và hỗ trợ 24/7.
            </p>
            <div className="flex space-x-4">
              <a href="#" className="text-gray-400 hover:text-primary transition-colors">
                <Facebook className="h-5 w-5" />
              </a>
              <a href="#" className="text-gray-400 hover:text-primary transition-colors">
                <Twitter className="h-5 w-5" />
              </a>
              <a href="#" className="text-gray-400 hover:text-primary transition-colors">
                <Github className="h-5 w-5" />
              </a>
            </div>
          </div>

          {/* Services */}
          <div>
            <h4 className="text-lg font-semibold text-white mb-6">Dịch vụ</h4>
            <ul className="space-y-4">
              <li>
                <Link href="#" className="text-gray-400 hover:text-primary transition-colors flex items-center">
                  <span className="bg-white/5 p-1 rounded mr-2">
                    <Mail className="h-4 w-4" />
                  </span>
                  VPS GPU
                </Link>
              </li>
              <li>
                <Link href="#" className="text-gray-400 hover:text-primary transition-colors flex items-center">
                  <span className="bg-white/5 p-1 rounded mr-2">
                    <Github className="h-4 w-4" />
                  </span>
                  Server GPU
                </Link>
              </li>
              <li>
                <Link href="#" className="text-gray-400 hover:text-primary transition-colors flex items-center">
                  <span className="bg-white/5 p-1 rounded mr-2">
                    <Phone className="h-4 w-4" />
                  </span>
                  Cloud GPU
                </Link>
              </li>
            </ul>
          </div>

          {/* Support */}
          <div>
            <h4 className="text-lg font-semibold text-white mb-6">Hỗ trợ</h4>
            <ul className="space-y-4">
              <li>
                <Link href="#" className="text-gray-400 hover:text-primary transition-colors">
                  Trung tâm trợ giúp
                </Link>
              </li>
              <li>
                <Link href="#" className="text-gray-400 hover:text-primary transition-colors">
                  Tài liệu API
                </Link>
              </li>
              <li>
                <Link href="#" className="text-gray-400 hover:text-primary transition-colors">
                  Liên hệ
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact */}
          <div>
            <h4 className="text-lg font-semibold text-white mb-6">Liên hệ</h4>
            <ul className="space-y-4">
              <li className="flex items-center space-x-3">
                <MapPin className="h-5 w-5 text-primary" />
                <span className="text-gray-400">297 Quang Trung, Thị Trấn Phù Mỹ, Huyện Phù Mỹ, Tỉnh Bình Định, Việt Nam</span>
              </li>
              <li className="flex items-center space-x-3">
                <Phone className="h-5 w-5 text-primary" />
                <span className="text-gray-400">+84 984 857 196</span>
              </li>
              <li className="flex items-center space-x-3">
                <Mail className="h-5 w-5 text-primary" />
                <span className="text-gray-400">contact@thuegpu.vn</span>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="mt-12 pt-8 border-t border-gray-800/50">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <p className="text-gray-400 text-sm">
              &copy; {new Date().getFullYear()} Thuegpu.vn. All rights reserved.
            </p>
            <div className="flex space-x-6">
              <Link href="#" className="text-gray-400 hover:text-primary text-sm transition-colors">
                Điều khoản dịch vụ
              </Link>
              <Link href="#" className="text-gray-400 hover:text-primary text-sm transition-colors">
                Chính sách bảo mật
              </Link>
            </div>
          </div>
        </div>
      </div>
    </motion.footer>
  )
}
